<template>
  <div style="padding-bottom: 200px">
    <RocketLoad :full="true" v-if="isLoading" />

    <template v-else-if="jobTemplate">
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>
            <template v-if="obj">Edit</template
            ><template v-else>Create New</template> Job Template
          </h1>
        </div>
      </div>
      <div class="flex" :style="{ marginTop: '14px' }">
        <Button
          label="Tag"
          :style="{
            backgroundColor: '#F2F4F6',
            color: '#B9C5D0',
            padding: '5px 10px',
            borderRadius: '14px',
          }"
          class="p-button-text text-sm p-button-secondary"
          icon="las la-plus"
        />
        <OverlayPanel ref="tagModal" appendTo="body"> </OverlayPanel>
      </div>
      <div>
        <div class="col-12 pl-0 flex align-items-start justify-content-start">
          <template>
            <div class="col-2">
              <Button
                label="General"
                class="p-button-text p-button-link p-button-secondary"
                icon="las la-suitcase"
              />
            </div>
            <div class="col-2">
              <Button
                label="Reccurring"
                class="p-button-text p-button-link p-button-secondary"
                icon="las la-redo-alt"
              />
            </div>
          </template>
        </div>
      </div>
      <form @submit.prevent="submitHandler" v-form-enter>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Job Template Name</label>
            <div class="p-inputgroup">
              <InputText placeHolder="" v-model="jobTemplate.name" />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label>Category</label>
            <div class="p-inputgroup">
              <Dropdown
                id="ae"
                :options="categories"
                optionLabel="description"
                optionValue="uid"
                v-model="jobTemplate.category"
                dataKey="uid"
                placeholder="Select Categories"
                class="input"
              />
            </div>
          </div>
          <div class="field col-12">
            <label for="name">Description</label>

            <Textarea
              id="Description"
              :autoResize="true"
              v-model="jobTemplate.description"
              rows="5"
            />
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button type="submit" label="SAVE" :input-class="['is-primary']" />
          <Button
            style=""
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { state, fetchCategories } from "../../../services/data_service.js";
export default {
  name: "UserSliderDetails",
  components: {},
  data() {
    return {
      isLoading: false,
      jobTemplate: {
        name: null,
        description: null,
      },
    };
  },
  async mounted() {},
  methods: {
    submitHandler() {},
    async loadData() {
      if (this.obj) {
        await Promise.all([fetchCategories()]);
      } else {
        await Promise.all([fetchCategories()]);
      }
    },
  },
  computed: {
    categories() {
      return state.categories;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
  },
};
</script>